<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="text-center printable">
        <v-col cols="12 text-start mx-auto" sm="11">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>

        <v-col cols="12 text-start mx-auto" sm="11">
          <v-card>
            <v-card-title class="bg-main darken-1 mx-auto">
              <span class="white--text">{{
                langkeyword("INVOICEINFORMATION")
              }}</span>
            </v-card-title>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-image</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("INVOICEIMAGE") }} :

                    <a target="_blank" :href="uploadPath(image)">
                      <v-btn v-if="image != '' && image != null && image != undefined
                      " rounded class="white--text bg-main" large>
                        <v-icon> mdi-image</v-icon>
                        {{ langkeyword("IMAGE") }}
                      </v-btn>
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-form @submit.prevent="changeAvatar" ref="form" lazy-validation class="text-center">
                    <v-file-input v-model="avatar" id="invoiceAvatar" name="avatar" :label="langkeyword('AVATAR')"
                      required prepend-icon="mdi-camera"></v-file-input>

                    <v-btn :disabled="avatar == null" small type="submit" class="bg-main m-auto white--text">
                      {{ langkeyword("CHANGE_AVATAR") }}
                    </v-btn>
                  </v-form>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("INVOICENUMBER") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("invoicenumber") }}
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-calendar-range</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("DATE") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("date") }}
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-format-list-bulleted-type</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("INVOICETYPE") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("invoicetype") }}
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("COMPANY") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn link class="primary" :to="'/dashboard/buy/companies/' +
                    companyInvoiceDetail('company_id')
                    ">{{ companyInvoiceDetail("company") }}</v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("CURRENCY") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("currency") }}
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("TOTAL") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ totalinvoice }}
                  {{ companyInvoiceDetail("symbol") }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-brightness-percent</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("DISCOUNT") }} :

                    <v-btn class="bg-main white--text" small @click="discountdialog = true">{{
                      langkeyword("CHANGE") }}</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("discount") }}
                  {{ companyInvoiceDetail("symbol") }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-brightness-percent</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("DRIVERWAGE") }} :

                    <v-btn class="white--text bg-main" small @click="driverwagedialog = true">{{
                      langkeyword("CHANGE") }}</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ companyInvoiceDetail("driverwage") }}
                  {{ companyInvoiceDetail("symbol") }}
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ langkeyword("ALLTOTAL") }} :
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{
                    totalinvoice +
                    companyInvoiceDetail("driverwage") -
                    companyInvoiceDetail("discount")
                  }}
                  {{ companyInvoiceDetail("symbol") }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-flex text-main justify-center">
        <v-col cols="11 printable">
          <v-data-table :headers="headers" :items="companyinvoiceitems" :search="search" sort-by="name"
            class="elevation-1 nzar-font radius-15" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><v-icon>mdi-shopping</v-icon>
                  {{ langkeyword("COMPANYINVOICEITEMS") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="1000px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" style="color: white;float:right" class="mb-2 radius-5 nzar-font"
                      v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-select v-if="isAdd() === true || isEdit() === true" @change="fetchProductByProduct($event)"
                            v-model="companyinvoiceitemsObject.product_id" :items="products"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('PRODUCT')"
                            dense></v-select>

                          <v-text-field type="number" min="0" v-model="barcode" class="nzar-font"
                            @keyup="fetchProductByBarcode($event)" :label="langkeyword('BARCODE')">
                          </v-text-field>

                          <v-card v-if="companyinvoiceitemsObject.product_id != null &&
                            companyinvoiceitemsObject.product_id != ''
                          ">
                            <v-card-title class="bg-main darken-1 mx-auto">
                              <span class="white--text">{{
                                langkeyword("PRODUCT")
                              }}</span>
                            </v-card-title>

                            <div class="col-12">
                              <v-img width="200px" height="200px" class="mx-auto rounded-lg"
                                :src="uploadPath(productinfo.avatar)">
                              </v-img>
                            </div>

                            <v-list>
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("SALEPRICE") }} ــ
                                    {{ langkeyword("IQD") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <span>{{ productinfo.sale_price_iqd }}</span>
                                </v-list-item-action>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("SALEPRICE") }} ــ
                                    {{ langkeyword("USD") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <span>{{ productinfo.sale_price_usd }}</span>
                                </v-list-item-action>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("BUYPRICE") }} ــ
                                    {{ langkeyword("IQD") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <span>{{ productinfo.buy_price_iqd }}</span>
                                </v-list-item-action>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("BUYPRICE") }} ــ
                                    {{ langkeyword("USD") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <span>{{ productinfo.buy_price_usd }}</span>
                                </v-list-item-action>
                              </v-list-item>


                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("PROFIT") }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-chip color="green" class="white--text">
                                    {{ profitOne }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>



                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("UNITONE") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-chip class="white--text bg-main">
                                    <span v-if="lang() === 'ku'">{{
                                      productinfo.unitsone_ku_name
                                    }}</span>
                                    <span v-if="lang() === 'ar'">{{
                                      productinfo.unitsone_ar_name
                                    }}</span>
                                    <span v-if="lang() === 'en'">{{
                                      productinfo.unitsone_en_name
                                    }}</span>
                                  </v-chip>
                                  <v-divider></v-divider>
                                  <v-chip color="primary">
                                    {{ productinfo.qty }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("UNITMANY") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-chip class="white--text bg-main">
                                    <span v-if="lang() === 'ku'">{{
                                      productinfo.unitsmany_ku_name
                                    }}</span>
                                    <span v-if="lang() === 'ar'">{{
                                      productinfo.unitsmany_ar_name
                                    }}</span>
                                    <span v-if="lang() === 'en'">{{
                                      productinfo.unitsmany_en_name
                                    }}</span>
                                  </v-chip>
                                  <v-divider></v-divider>
                                  <v-chip color="primary">
                                    {{
                                      productinfo.qty /
                                      productinfo.unit_one_from_many
                                    }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ langkeyword("UNITONEFROMMANY") }} :
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-chip color="primary">
                                    {{ productinfo.unit_one_from_many }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>

                          <br />

                          <v-text-field v-if="productSelected" type="number" min="0" step="any" class="nzar-font"
                            v-model="companyinvoiceitemsObject.unit_many_qty" :label="langkeyword('UNITMANY')">
                          </v-text-field>

                          <v-text-field v-if="productSelected" type="number" min="0" step="any" class="nzar-font"
                            v-model="companyinvoiceitemsObject.unit_many_qty_inside
                              " :label="langkeyword('UNITONEFROMMANY')">
                          </v-text-field>

                          <v-text-field v-if="productSelected" type="number" min="0" step="any" class="nzar-font"
                            v-model="companyinvoiceitemsObject.buy_price" :label="langkeyword('BUYPRICE')">
                          </v-text-field>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCompanyInvoiceItems">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark @click="
                        updateCompanyInvoiceItems(
                          companyinvoiceitemsObject.id
                        )
                        ">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="
                        deleteCompanyInvoiceItems(
                          companyinvoiceitemsObject.id
                        )
                        ">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12 text-center mx-auto" md="10">
                    <v-text-field class="nzar-font" v-model="search" append-icon="mdi-magnify"
                      :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!--  -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon small class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="discountdialog" persistent max-width="600px">
          <v-card class="text-center">
            <v-card-title>
              <span class="nzar-font">{{ langkeyword("TAKEDISCOUNT") }}</span>
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field type="number" min="0" v-model="discountvalue" :label="langkeyword('AMOUNT')"
                  required></v-text-field>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red lighten-1 white--text" @click="discountdialog = false">
                {{ langkeyword("CLOSE") }}
              </v-btn>
              <v-btn class="white--text bg-main" @click="discountAction">
                {{ langkeyword("OK") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="driverwagedialog" persistent max-width="600px">
          <v-card class="text-center">
            <v-card-title>
              <span class="nzar-font">{{ langkeyword("TAKEDRIVERWAGE") }}</span>
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field type="number" min="0" v-model="driverwagevalue" :label="langkeyword('AMOUNT')"
                  required></v-text-field>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red lighten-1 white--text" @click="driverwagedialog = false">
                {{ langkeyword("CLOSE") }}
              </v-btn>
              <v-btn class="white--text bg-main" @click="driverwageAction">
                {{ langkeyword("OK") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row>
        <v-col cols="11" class="ma-auto">
          <v-expansion-panels popout hover focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="mode" expand-icon="mdi-shopping">
                {{ langkeyword("PRODUCTS") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode">
                <products :shownavandfooter="false" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import ProductRequests from "../../../requests/dashboard/product/Products";
import CompanyInvoicesRequests from "../../../requests/dashboard/buy/CompanyInvoices";
import CompanyInvoiceItemsRequests from "../../../requests/dashboard/buy/CompanyInvoiceItems";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import products from "../../dashboard/product/products.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
    products,
  },
  data() {
    return {
      discountdialog: false,
      discount: 0,
      discountvalue: 0,
      productSelected: false,
      profitOne: 0,
      driverwagedialog: false,
      driverwage: 0,
      driverwagevalue: 0,
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      companyinvoiceitems: Vue.prototype.getters().getcompanyinvoiceitems,
      totalinvoice: 0,
      productinfo: {},
      barcode: null,
      avatar: null,
      image: null,
      search: "",
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center" },
        {
          text: Vue.prototype.langkeyword("PRODUCT"),
          value: "products_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("UNITMANY"),
          value: "unit_many_qty",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("UNITONEFROMMANY"),
          value: "unit_many_qty_inside",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("QUANTITY"),
          value: "qty",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("BUYPRICEONE"),
          value: "buy_price",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("TOTAL"),
          value: "total",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("ACTIONS"),
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
      CURRENTFORM: "add",
      companyinvoiceitemsObject: {
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        companyinvoice_id: this.$route.params.id,
        product_id: "",
        unit_many_qty: "",
        unit_many_qty_inside: "",
        buy_price: "",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
    setTimeout(() => {
      this.image = this.companyInvoiceDetail("avatar");
      this.discountvalue = this.discount;
      this.driverwagevalue = this.driverwage;
    }, 1000);
  },
  computed: {
    products() {
      return this.$store.getters.getproducts;
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.fetchProductByProduct(item.product_id);
      this.CURRENTFORM = "edit";
      this.companyinvoiceitemsObject = Object.assign({}, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.companyinvoiceitemsObject = Object.assign({}, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.companyinvoiceitemsObject = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.companyinvoiceitemsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.companyinvoiceitemsObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    profitCalculate() {
      if (this.companyInvoiceDetail("currency_id") == 2) {
        this.profitOne = (this.productinfo.sale_price_usd - this.productinfo.buy_price_usd);
      } else {
        this.profitOne = (this.productinfo.sale_price_iqd - this.productinfo.buy_price_iqd);
      }
    },
    discountAction() {
      if (this.discountvalue <= this.totalinvoice) {
        CompanyInvoicesRequests.discount(
          {
            discountCompanyInvoices: true,
            companyinvoice_id: this.$route.params.id,
            discount: this.discountvalue,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.currentcompanyinvoicedetail = res.data.data;
              this.discountvalue =
                this.$store.state.currentcompanyinvoicedetail.discount;
              this.discountdialog = false;
              this.productSelected = false
            }
            this.cleanMessages();
          }
        );
      } else {
        this.error_msg = this.langkeyword("NOT_VALID_AMOUNT");
        this.discountdialog = false;
      }
    },

    driverwageAction() {
      if (this.driverwagevalue <= this.totalinvoice) {
        CompanyInvoicesRequests.driverwage(
          {
            driverwageCompanyInvoices: true,
            companyinvoice_id: this.$route.params.id,
            driverwage: this.driverwagevalue,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.currentcompanyinvoicedetail = res.data.data;
              this.driverwagevalue =
                this.$store.state.currentcompanyinvoicedetail.driverwage;
              this.driverwagedialog = false;
              this.productSelected = false
            }
            this.cleanMessages();
          }
        );
      } else {
        this.error_msg = this.langkeyword("NOT_VALID_AMOUNT");
        this.driverwagedialog = false;
      }
    },
    fetchProductByProduct(productId) {
      ProductRequests.getOne(
        {
          getOneProducts: true,
          product_id: productId,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.productinfo = res.data.data;
            this.barcode = this.productinfo.barcode;
            this.profitCalculate()
            this.productSelected = true
          }
          this.cleanMessages();
        }
      );
    },

    fetchProductByBarcode(event) {
      ProductRequests.barCode(
        {
          getOneProducts: true,
          barcode: event.target.value,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.productinfo = res.data.data;
            this.companyinvoiceitemsObject.product_id = this.productinfo.id;
            this.profitCalculate()
            this.productSelected = true
          }
          this.cleanMessages();
        }
      );
    },

    changeAvatar() {
      const filename = document.getElementById("invoiceAvatar").files[0];
      const avatarParams = new FormData();
      avatarParams.append("changeAvatarCompanyInvoices", true);
      avatarParams.append("companyinvoice_id", this.$route.params.id);
      avatarParams.append("avatar", filename);
      const config = {
        headers: {
          "content-type": `multipart/form-data; boundary=${avatarParams._boundary}`,
        },
      };
      CompanyInvoicesRequests.changeAvatar(avatarParams, config, (res) => {
        if (res.data.status === false) {
          this.error_msg = this.langkeyword(res.data.msg);
        } else if (res.data.status === true) {
          document.getElementById("invoiceAvatar").value = null;
          this.avatar = null;
          this.image = res.data.avatar;
          this.error_msg = null;
          this.success_msg = this.langkeyword(res.data.msg);
          this.$store.state.companyinvoices = res.data.data;
          this.productSelected = false
        }
        this.cleanMessages();
      });
    },

    saveCompanyInvoiceItems() {
      this.validate(
        [
          this.$route.params.id,
          this.companyinvoiceitemsObject.product_id,
          this.companyinvoiceitemsObject.unit_many_qty,
          this.companyinvoiceitemsObject.unit_many_qty_inside,
          this.companyinvoiceitemsObject.buy_price,
        ],
        () => {
          CompanyInvoiceItemsRequests.add(
            {
              addCompanyInvoiceItems: true,
              companyinvoice_id: this.$route.params.id,
              product_id: this.companyinvoiceitemsObject.product_id,
              unit_many_qty: this.companyinvoiceitemsObject.unit_many_qty,
              unit_many_qty_inside:
                this.companyinvoiceitemsObject.unit_many_qty_inside,
              buy_price: this.companyinvoiceitemsObject.buy_price,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.products;
                this.readData();
                this.productSelected = false
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCompanyInvoiceItems(companyinvoiceitemId) {
      this.validate(
        [
          this.$route.params.id,
          this.companyinvoiceitemsObject.product_id,
          this.companyinvoiceitemsObject.unit_many_qty,
          this.companyinvoiceitemsObject.unit_many_qty_inside,
          this.companyinvoiceitemsObject.buy_price,
        ],
        () => {
          CompanyInvoiceItemsRequests.edit(
            {
              editCompanyInvoiceItems: true,
              companyinvoiceitem_id: companyinvoiceitemId,
              companyinvoice_id: this.$route.params.id,
              product_id: this.companyinvoiceitemsObject.product_id,
              unit_many_qty: this.companyinvoiceitemsObject.unit_many_qty,
              unit_many_qty_inside:
                this.companyinvoiceitemsObject.unit_many_qty_inside,
              buy_price: this.companyinvoiceitemsObject.buy_price,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.products;
                this.readData();
                this.productSelected = false
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCompanyInvoiceItems(companyinvoiceitemId) {
      CompanyInvoiceItemsRequests.delete(
        {
          deleteCompanyInvoiceItems: true,
          companyinvoiceitem_id: companyinvoiceitemId,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.products = res.data.products;
            this.readData();
            this.productSelected = false
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    companyInvoiceDetail(field) {
      let actField = "";
      if (field === "invoicetype") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_en_name;
        }
      }
      if (field === "currency") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_en_name;
        }
      } else if (field === "date") {
        actField = this.$store.state.currentcompanyinvoicedetail.date;
      } else if (field === "symbol") {
        actField =
          this.$store.state.currentcompanyinvoicedetail.currencies_symbol;
      } else if (field === "invoicenumber") {
        actField = this.$store.state.currentcompanyinvoicedetail.invoicenumber;
      } else if (field === "company") {
        actField = this.$store.state.currentcompanyinvoicedetail.companies_name;
      } else if (field === "company_id") {
        actField = this.$store.state.currentcompanyinvoicedetail.company_id;
      } else if (field === "total") {
        actField = this.$store.state.currentcompanyinvoicedetail.total;
      } else if (field === "avatar") {
        actField = this.$store.state.currentcompanyinvoicedetail.avatar;
      } else if (field === "discount") {
        this.discount = this.$store.state.currentcompanyinvoicedetail.discount;
        actField = this.$store.state.currentcompanyinvoicedetail.discount;
      } else if (field === "driverwage") {
        this.driverwage =
          this.$store.state.currentcompanyinvoicedetail.driverwage;
        actField = this.$store.state.currentcompanyinvoicedetail.driverwage;
      }
      return actField;
    },
    readData() {
      CompanyInvoicesRequests.getOne(
        {
          getOneCompanyInvoices: true,
          companyinvoice_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.$store.state.currentcompanyinvoicedetail = res.data.data;
            this.discountvalue =
              this.$store.state.currentcompanyinvoicedetail.discount;
            this.driverwagevalue =
              this.$store.state.currentcompanyinvoicedetail.driverwage;
          }
          this.cleanMessages();
        }
      ),
        CompanyInvoiceItemsRequests.getAll(
          {
            getAllCompanyInvoiceItems: true,
            companyinvoice_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.companyinvoiceitems = res.data.data;
              this.companyinvoiceitems = res.data.data;
              this.totalinvoice = res.data.totalinvoice;
            }
            this.cleanMessages();
          }
        );

      setTimeout(() => {
        if (!this.permissionExist("#view_companyinvoices")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
  },
};
</script>
<style scoped>
@media print {
  .notprintable {
    display: none;
  }

  .printable {
    display: block;
  }
}
</style>